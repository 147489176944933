<script setup lang="ts">
interface Props {
  link: string
}
const props = defineProps<Props>()
const { t } = useI18n()
const { config } = useCms()

const SOCIAL_MEDIA_ICONS = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  TIKTOK: 'tiktok',
  PINTEREST: 'pinterest',
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  X: 'x',
}

const iconName = computed(() => {
  for (const socialMedia in SOCIAL_MEDIA_ICONS) {
    if (props.link.includes(socialMedia.toLowerCase())) {
      return SOCIAL_MEDIA_ICONS[socialMedia as keyof typeof SOCIAL_MEDIA_ICONS]
    }
  }
  return SOCIAL_MEDIA_ICONS.X
})
</script>

<script lang="ts">
export default {
  name: 'SocialLink',
}
</script>

<template>
  <NuxtLink
    :to="link"
    target="_blank"
    :title="
      t('links.visitAt', {
        name: config?.name,
        socialNetwork: t(`links.${iconName}`),
      })
    "
    class="flex h-8 w-8 items-center justify-center [&>svg]:size-8 [&>svg]:fill-current"
  >
    <SvgSocialFacebook v-if="iconName === 'facebook'" />
    <SvgSocialTwitter v-else-if="iconName === 'twitter' || iconName === 'x'" />
    <SvgSocialTiktok v-else-if="iconName === 'tiktok'" />
    <SvgSocialPinterest v-else-if="iconName === 'pinterest'" />
    <SvgSocialYoutube v-else-if="iconName === 'youtube'" />
    <SvgSocialInstagram v-else-if="iconName === 'instagram'" />
    <SvgSocialLinkedin v-else-if="iconName === 'linkedin'" />
  </NuxtLink>
</template>

<i18n>
de:
  links:
    visitAt: "{name} auf {socialNetwork} besuchen"
    facebook: "Facebook"
    twitter: "Twitter"
    tiktok: "TikTok"
    pinterest: "Pinterest"
    youtube: "YouTube"
    instagram: "Instagram"
    linkedin: "LinkedIn"
    x: "X"
es:
  links:
    visitAt: "Visitar {name} en {socialNetwork}"
    facebook: "Facebook"
    twitter: "Twitter"
    tiktok: "TikTok"
    pinterest: "Pinterest"
    youtube: "YouTube"
    instagram: "Instagram"
    linkedin: "LinkedIn"
    x: "X"
</i18n>
